import {T} from '@deity-io/falcon-i18n';
import loadable from '@loadable/component';
import React, {useEffect} from 'react';
import ProductHeader from '../../components/Product/ProductHeader/ProductHeader';
import {ProductProvider} from '../../context/ProductContext';
import useResponsive from '../../hooks/useResponsive';
import {useSamitoReset} from '../../hooks/useSamito';
import StructuralDataProduct from '../../components/StructuralData/StructuralDataProduct';
import './Product.scss';
import TradedoublerContainerTag from '../../components/Tracking/Tradedoubler/TradedoublerContainerTag'
import SeoBlock from "../../components/SeoBlock";
import {useQuarticonContext} from "../../context/QuarticonContext";
import {sendProductsQuarticon} from "../../hooks/useQuarticonHooks";
import {productDetailEvent} from '../../hooks/useAnalytics';
import ProductPage from "./Product";
import Inspiration from "./ProductInspiration/Inspiration";
import {normalizeProductItem} from "../../components/Category/Products/Products";

const QuarticonRecommendations = loadable(() => import('../../components/Quarticon/QuarticonRecommendations'), {
    ssr: false,
});
const Helmet = loadable(() => import('react-helmet'));

const Products = loadable(() => import('../../components/Category/Products/Products'), {ssr: false});

const ProductRenderer = ({routeData}) => {
    const {isMobile} = useResponsive();

    const product = normalizeProductItem(routeData);

    const {
        sku,
        page_layout,
        related_products,
        upsell_products,
        inspirations,
        brand,
    } = product || {};

    let componentToRender;
    let hideRelatedProducts=false
    let hideUpsellProducts=false
    let hideQuarticon = !routeData.hide_quarticon ? false : routeData.hide_quarticon;

    switch (page_layout){
        case 'inspiration':
            hideQuarticon = true
            hideRelatedProducts=true
            hideUpsellProducts=true
            componentToRender=<Inspiration product={product}/>
            break;
        default:
            componentToRender=<ProductPage product={product}/>
    }


    useSamitoReset();

    const { tracker } = useQuarticonContext();
    useEffect(()=>{
        sendProductsQuarticon(sku, tracker)
        productDetailEvent(product)
    },[sku])

    return (
        <ProductProvider value={product}>
            <SeoBlock routeData={routeData}/>
            <main className="product">
                <Helmet>
                    {brand === 'BOSCH' && <script defer async src="https://cdn.loadbee.com/js/loadbee_integration.js"/>}
                </Helmet>
                <TradedoublerContainerTag page="product" product={product}></TradedoublerContainerTag>
                <StructuralDataProduct
                    product={product}
                />
                {!isMobile && <ProductHeader product={product}/>}
                <article className="product__main2">
                    {componentToRender}
                </article>
            </main>
        </ProductProvider>
    );
}

export default ProductRenderer